import React from "react"
import {BrowserRouter,Route, Switch} from 'react-router-dom'
import GetMobileScreen from "./utils/getMobileScreen"

import Menu from "./component/menu/desktop"
import MenuMobile from "./component/menu/mobile"
import Home from "./pages"
import Footer from "./component/footer"
import Solutions from "./pages/solutions"

const Routes = () => {
    return(
        <BrowserRouter>
           {GetMobileScreen() ? <MenuMobile /> : <Menu/> } 
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/solucoes" component={Solutions} />
            </Switch>
            <Footer/>    
        </BrowserRouter>
    )
    
}

export default Routes;