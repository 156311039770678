import styled from 'styled-components';

export const Background = styled.div`
    
    background-repeat:no-repeat!important;
    background-size:cover!important;
    background-position-y:center!important;
    display:flex;
    align-items:center;
    justify-content:center;
    height:522px;
    @media(min-width:765px){
        background:url("https://lp-bria-assets.s3.amazonaws.com/home/banner-last.png");
    }
`;
export const Wrap = styled.div`
    display:flex;
    flex-direction:column;

    @media(min-width:765px){
        margin-left:20rem;
    }
    @media(max-width:765px){
        background:#F88675;
        padding:2rem;
    }
`;
export const Title = styled.p`
    color:#2F054D;
    font-size:36px;
    font-weight:bold;
    font-family:"Montserrat";
    margin:0;
`;
export const Subtitle = styled.p`
    color:#fff;
    font-size:36px;
    font-weight:bold;
    font-family:"Montserrat";
    margin:0;
    height:fit-content;
`;
export const ImgB = styled.img`
    width:100%;
`;