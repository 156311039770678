import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 200px;
  align-content: center;
  justify-content: center;
  a img {
    display: flex;
  }
`;

export const Address = styled.p`
  margin: 0 auto;
  font-size: 12px;
  color: #fff;
  margin-top: 10px;
  font-family: "Montserrat";
  text-align: center;
`;

export const Background = styled.div`
  width: 100%;

  display: flex;
  background-color: #2f054d;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 765px) {
    height: 295px;
  }
  @media (max-width: 765px) {
    height: 272px;
  }
`;
export const WrapSocialMedia = styled.div`
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0px auto;
  margin-top: 2rem;
`;
