import {
  Container,
  WrapTitle,
  Title,
  Form,
  ButtonSubmit,
  Background,
  Subtitle,
} from "./style";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
//import axios from "axios";
import { useState } from "react";

const theme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        borderBottomColor: "red!important",
        borderColor: "red!important",
      },
    },
    MuiTextField: {
      root: {
        marginBottom: "1rem",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#2F054D",
          fontWeight: 600,
          fontSize: "14px",
          textAlign: "initial",
        },
        color: "white",
        margin: "auto",
        display: "flex",
      },
      focused: {},
    },
    MuiInputLabel: {
      outlined: {
        borderBottomColor: "red",
      },
      root: {
        position: "absolute",
        textAlign: "center",
        width: "100%",
        color: "#2F054D",
        fontWeight: 200,
        fontSize: "24px",
        lineHeight: "2px",
        "&$shrink": {
          transform: "translate(0px, 0px) scale(1)!important",
        },
      },
      focused: {},
    },
    MuiInput: {
      root: {
        color: "#fff",
        fontSize: "13px",
        textAlign: "center",
        width: "100%",
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #2F054D",
        },
        "&:after": {
          borderBottom: `2px solid #2F054D`,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid #2F054D`,
        },
      },
      focused: {},
    },
    MuiFilledInput: {
      underline: {
        "&:before": {
          borderBottomColor: "red",
        },
        "&:hover:not(.Mui-focused):before": {
          borderBottomColor: "green",
        },
        "&:after": {
          // focused
          borderBottomColor: "purple",
        },
      },
    },
  },
});

const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [option, setOption] = useState("");

  const submitLead = () => {
    /* if (name !== "" && email !== "" && phone !== "") {
      axios
        .post("https://api.video.jumpy.digital/v1/email", { name, email, phone })
        .then((result) => {
          console.log(result.data);
        });
      console.log({ name, email, phone });
    }*/
  };

  return (
    <Background id= "contact">
      <Container>
        <MuiThemeProvider theme={theme}>
          <WrapTitle>
            <Title>Quer saber mais?</Title>
            <Subtitle>Entre em contato com nosso time.</Subtitle>
          </WrapTitle>
          <Form noValidate onSubmit={submitLead}>
            <TextField
              inputProps={{ min: 0, style: { textAlign: "center" } }}
              id="standard-basic"
              label="Nome*"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              inputProps={{ min: 0, style: { textAlign: "center" } }}
              id="standard-basic"
              label="E-mail*"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              inputProps={{ min: 0, style: { textAlign: "center" } }}
              id="standard-basic"
              label="Telefone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <FormControl>
              <InputLabel htmlFor="grouped-select">Interesse</InputLabel>
              <Select
                defaultValue=""
                id="grouped-select"
                onChange={(e) => setOption(e.target.value)}
              >
                <MenuItem value={"Jornada completa"}>Jornada completa</MenuItem>
                <MenuItem value={"DPS inteligente"}>DPS inteligente</MenuItem>
                <MenuItem value={"Inteligência em saúde"}>
                  Inteligência em saúde
                </MenuItem>
                <MenuItem value={"Dashboard analítico"}>
                  Dashboard analítico
                </MenuItem>
                <MenuItem value={"Bem-estar e gamificação"}>
                  Bem-estar e gamificação
                </MenuItem>
              </Select>
            </FormControl>
            <ButtonSubmit
              href={`mailto:projetos@briasaude.com?subject=Contato Site: ${email}&body=Nome: ${name}, Email: ${email}, Telefone: ${phone}, Interesse: ${option}`}
            >
              Enviar
            </ButtonSubmit>
          </Form>
        </MuiThemeProvider>
      </Container>
    </Background>
  );
};

export default Contact;
