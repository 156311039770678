import {Background,Container,WrapContent,HeaderContent,Body,Img, Section} from "./styled"

const SolutionsDesktop = () => {
    return(
        <Background>
            <Container>
                <Section>
                    <WrapContent id={0}>
                        <HeaderContent >
                            <img src="https://lp-bria-assets.s3.amazonaws.com/solutions/1.png" alt=""/>
                            <p style={{marginTop:'19px'}}>Contratação:</p>
                        </HeaderContent>
                        <Body>
                            A integração com cotadores é 100% digital e amigável, sem ligações ou processos burocráticos. Ao responder todas as perguntas, automaticamente o usuário terá acesso ao preço, cobertura e condições especiais.
                        </Body>
                    </WrapContent>
                    <Img id={0} alt="" src="https://lp-bria-assets.s3.amazonaws.com/solutions/desktop1.png" />
                </Section>
                <Section>
                    
                    <WrapContent id={1}>
                        <HeaderContent>
                            <img src="https://lp-bria-assets.s3.amazonaws.com/solutions/2.png" alt=""/>
                            <p style={{marginTop:'19px'}}>DPS inteligente:</p>
                        </HeaderContent>
                        <Body>
                            O segurado faz sua declaração prévia de saúde online e sem burocracia. Enquanto isso, o algoritmo inteligente analisa todos os dados, como premissas atuariais de saúde e informações socioeconômicas. Assim que a avaliação é finalizada, em poucos instantes, o usuário tem acesso ao status de avaliação e próximos passos.
                        </Body>
                    </WrapContent>
                    <Img id={1} alt="" src="https://lp-bria-assets.s3.amazonaws.com/solutions/desktop2.png" />
                </Section>
                <Section>
                    <WrapContent style={{width:"350px"}} id={2}>
                        <HeaderContent >
                            <img src="https://lp-bria-assets.s3.amazonaws.com/solutions/3.png" alt=""/>
                            <p>Inteligência <br/> em saúde:</p>
                        </HeaderContent>
                        <Body>
                            Para maior segurança na tomada de decisão, disponibilizamos uma equipe de saúde formada por médicos e enfermeiros. Time responsável pelo processo de avaliação de riscos via telesubscrição. Ao final da consulta, o relatório de saúde traz a segurança necessária para a venda de seguros, com comentários e observações via CRM digital.
                        </Body>
                    </WrapContent>
                    <Img id={2} alt="" src="https://lp-bria-assets.s3.amazonaws.com/solutions/desktop3.png" />
                </Section>
                <Section>
                    <WrapContent id={3}>
                        <HeaderContent>
                            <img src="https://lp-bria-assets.s3.amazonaws.com/solutions/4.png" alt=""/>
                            <p>Dashboard analítico:</p>
                        </HeaderContent>
                        <Body>
                        Acesso ao dashboard analítico com todos os indicativos relevantes para a tomada de decisão e conhecimento da carteira de clientes, como:
                        <br/>
                        <br/>
                        • Visualização dos dados de subscrição;<br/>
                        • Avaliação de risco;<br/>
                        • Status de aprovação;<br/>
                        • Análises quantitativas e qualitativas;<br/>
                        • Download de documentação;<br/>
                        • Retroalimentação da base atuarial;<br/>
                        • Segmentação de base.<br/>
                        </Body>
                    </WrapContent>
                    <Img id={3} alt="" src="https://lp-bria-assets.s3.amazonaws.com/solutions/desktop4.png" />
                </Section>
                <Section>
                    <WrapContent style={{width:"350px"}} id={4}>
                        <HeaderContent >
                            <img src="https://lp-bria-assets.s3.amazonaws.com/solutions/5.png" alt=""/>
                            <p>Bem-estar e gamificação:</p>
                        </HeaderContent>
                        <Body>
                        Todos os segurados têm acesso ao portal Bria Saúde, um aplicativo para o cuidado completo dos clientes em âmbito físico, mental e emocional. Todo esse processo é gamificado para auxiliá-lo na definição de metas personalizadas, conquista de pontuações e resgate de premiações.
                        </Body>
                    </WrapContent>
                    <Img id={4} alt="" src="https://lp-bria-assets.s3.amazonaws.com/solutions/desktop5.png" />
                </Section>
                <Section>
                    <WrapContent id={5}>
                        <HeaderContent>
                            <img src="https://lp-bria-assets.s3.amazonaws.com/solutions/6.png" alt=""/>
                            <p>Assistência humanizada:</p>
                        </HeaderContent>
                        <Body>
                            Time de atendimento disponível para facilitar todo o processo de forma humanizada e efetiva aos familiares do segurado. O canal de atendimento será exclusivo para dúvidas, indenizações e interações gerais durante a ativação do seguro.
                        </Body>
                    </WrapContent>
                    <Img id={5} alt="" src="https://lp-bria-assets.s3.amazonaws.com/solutions/desktop6.png" />
                </Section>
            </Container>
        </Background>
    )
}

export default SolutionsDesktop