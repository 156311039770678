import styled, { keyframes } from "styled-components"
import { bounceInLeft } from 'react-animations';

export const Background = styled.div`
    background-image:url("https://lp-bria-assets.s3.amazonaws.com/home/banner1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
`

export const ContainerHome = styled.div`
    display:flex;
 
    max-width: 1140px;
    margin:auto;
    @media(min-width:765px){
        height:493px;
        align-items:center;

    }
    @media(max-width:765px){
        height:881px;
    }
`
export const WrapHeader = styled.div`
    display:flex;
    flex-direction:column;
    img{
        display:flex;
        width:98.29px;
        height:45.46px;
    }
    p{
        color:white;
        font-size:40px;
        font-weight:700;
        font-family:"montserrat";
        margin-top:1rem;
        animation: 1.5s ${keyframes`${bounceInLeft}`};
    }

    @media(max-width:765px){
        margin-top:2rem;
        margin-left:2rem;
    }
`


