import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

export const Header = styled.p`
  color:#fff;
  font-size:36px;
  font-family:"Montserrat";
  margin:0;
  width:546px;

  animation: 2s ${keyframes`${fadeIn}`};
  @media(min-width:765px){
    width:546px;
    margin:7rem  auto;
    text-align:center;
  }
  @media(max-width:765px){
    width:100%;
    padding:15px;
    box-sizing: border-box;
    margin:3rem 0;
  }
`
export const Background = styled.div`
    background:#2F054D;
    display:flex;
    flex-direction:column;
    align-content:center;
    .a::after{
    content:" indústria de seguros e parceiros. ";
    color:#F88675;
    };
    .a::before{
    content:" "
    }
`;