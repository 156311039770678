import React from "react"
import Routes  from "./routes"

import "./style.css"

const App = () => {
  return(
    <>  
      <Routes/>
    </>
  )

}

export default App;
