import styled from "styled-components";

export const ButtonSubmit = styled.a`
  padding: 15px;
  background-color: transparent;
  color: #2f054d;
  border: 2px solid #2f054d;
  border-radius: 15px;
  text-align: center;
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: bold;
  margin-top: 2rem;
  @media (min-width: 765px) {
    margin-bottom: 2rem;
  }
  :hover {
    background: #2f054d;
    border: 2px solid #f88675;
    color: #f88675;
    transition: 0.3s;
    cursor: pointer;
  }
`;
export const Background = styled.div`
  background-color: #f88675;
`;
export const Container = styled.div`
  max-width: 1170px;
  margin: auto;
  display: flex;
  padding-top: 5rem;
  padding-bottom: 3.5rem;
  flex-direction: column;
  align-items: center;
  @media (max-width: 765px) {
    padding-top: 5rem;
    padding-bottom: 3.5rem;
  }
`;
export const WrapTitle = styled.div`
  width: 100%;
  align-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  div {
    display: flex;
    flex-direction: row-reverse;
    img {
      width: 116px;
    }
  }
`;
export const Title = styled.h1`
  margin: 0;
  font-size: 50px;
  color: #2f054d;
  text-align: center;
  font-family: "Montserrat";
  font-weight: bold;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 290px;
  justify-content: space-between;
  @media (min-width: 765px) {
    width: 450px;
  }
`;

export const Img = styled.img`
  width: 125px;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

export const Subtitle = styled.p`
  color: #fff;
  font-size: 24px;
  font-weight: normal;
  font-family: "Montserrat";
  text-align: center;
`;
