import Core from "../component/solutions/core"
import Header from "../component/solutions/header"
import Finish from "../component/solutions/partnership"
const Solutuions = () =>{
    return(
        <>  
            <Header/>
            <Core/>
            <Finish/>
        </>
    )
}
export default Solutuions