import {useState,useEffect} from "react"
import {
    WrapHeader,
    WrapMenuMobile,Logo,MenuItens,ButtonIntes, ButtomMenu,ButtonExit,Hr,WrapSocialMedia , LogoMenu} from "./styled.js"
import { useHistory} from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

const MenuMobile = () => {
    const [pathLocation, setPathLocation] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const [refresh,setRefresh] = useState(false)
    let history = useHistory();

    const toggleActive = (pathButton) => {
        if(pathButton === pathLocation){
            return true
        }else{
            return false 
        }
    }
    useEffect(()=>{
        if(window){
            setPathLocation(window.location.pathname)
        }
    },[refresh])

    const handdleRedirect = (path) =>{
        setRefresh(!refresh);
        setShowMenu(false);
        history.push(path);
    }

    return(
        <>
            <WrapMenuMobile>
                <Logo src="https://lp-bria-assets.s3.amazonaws.com/B-logo.png" alt=""/>
                <ButtomMenu onClick={()=>setShowMenu(!showMenu)}>
                    <img src="https://lp-bria-assets.s3.amazonaws.com/iconMenuHamburguer.png" alt="" />
                </ButtomMenu>
            </WrapMenuMobile>
            {
                showMenu &&
                <MenuItens>
                    <WrapHeader>
                        <LogoMenu src="https://lp-bria-assets.s3.amazonaws.com/briaLogo.png" alt=""/>
                        <ButtonExit onClick={()=>setShowMenu(false)}>
                            <img src="https://lp-bria-assets.s3.amazonaws.com/exit.png" alt=""/>
                        </ButtonExit>
                    </WrapHeader>
                   
                    <ButtonIntes 
                        active={toggleActive("/")}
                        onClick={()=>handdleRedirect("/")}>
                        home
                    </ButtonIntes>
                    <Hr/>
                    <ButtonIntes 
                        active={toggleActive("/solucoes")}
                        onClick={()=>handdleRedirect("/solucoes")}>
                        Soluções
                    </ButtonIntes>
                    <Hr/>
                    <ButtonIntes 
                        active={toggleActive("/solucoes")}
                        onClick={()=>handdleRedirect("/")}>
                                <Link style={{textDecoration:"none",color:"white"}} to="/#contact">Contato</Link>
                    </ButtonIntes>
                    
                    <WrapSocialMedia>
                        <a href="/#">
                        <img src={"https://lp-bria-assets.s3.amazonaws.com/facebook.png"} alt="" />
                        </a> 
                        <a href="/#" >
                        <img src={"https://lp-bria-assets.s3.amazonaws.com/instagram.png"} alt=""/>
                        </a> 
                        <a href="/#" >
                        <img src={"https://lp-bria-assets.s3.amazonaws.com/linkedin.png"} alt="" />
                        </a> 
                    </WrapSocialMedia>
                </MenuItens>
            }
        </>
    )
}

export default MenuMobile