import styled from "styled-components"

export const Wrap = styled.div`
    width:100%;
    height:76px;
    background-color:#2F054D;
`;
export const Container = styled.div`
    max-width:1140px;
    height:100%;
    margin:auto;
    display:flex;
    justify-content:space-between;
    align-items:center;
    flex-direction:row;
    img{
        display:flex;
    }
`
export const WrapButtons = styled.div`
    display:flex;
    align-items:center;
`
export const Button = styled.div`
    font-size:18px;
    line-height:21.94px;
    color:  ${props => props.active === true ? "#F88675" : "white"};
    background-color:transparent;
    border:none;
    font-weight:${props => props.active === true ? 700 : 500};
    font-family:"Montserrat";
    margin:0px 20px;
    :hover{
        font-weight:700;
    }
`
export const WrapSocialMedia = styled.div`
    display:flex;
    a{
        cursor: pointer;
        margin:0px 5px;
    }
   
`
export const Hr = styled.div`
    height:17px;
    width:1px;
    background-color:white;
`
