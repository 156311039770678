import styled, { keyframes } from 'styled-components';
import { pulse } from 'react-animations';

export const Background = styled.div`
    background-color:#2F054D;
    background-image:url("https://lp-bria-assets.s3.amazonaws.com/home/bd-pingado.png");
    padding:4rem 0rem;
    box-sizing:border-box;
    background-repeat:no-repeat;
 
`;
export const Button = styled.button`
    display:flex;
    background-color:transparent;
    border:3px solid #F88675;
    font-size:24px;
    font-weight:700;
    font-family:"Montserrat";
    margin: auto;
    margin-bottom:3rem;
    color:#F88675;
    border-radius:15px;
    cursor: pointer;
   :hover{
       background-color:#F88675;
       color:#2F054D;
       border:3px solid #2F054D;
       transition:1s;
   }
   @media(min-width:765px){
        padding: 1.5rem 7rem;
   }
   @media(max-width:765px){
        padding: 1rem 2rem;
   }
`;
export const  H1 = styled.h1`
    text-align: center;
    font-size:50px;
    font-weight:700;
    font-family:"Montserrat";
    color:#fff;
`
export const  P = styled.p`
    text-align: center;
    font-size:24px;
    font-weight:400;
    font-family:"Montserrat";
    color:#fff;
    max-width:378px;
    margin:0 auto;
    @media(max-width:765px){
        width:249px;
    }
`
export const Wrap = styled.div`
    display:flex;
    margin:6rem auto;
    margin-bottom:14rem;
 
    background-position:center;

    @media(min-width:765px){
        background-image:url("https://lp-bria-assets.s3.amazonaws.com/home/vector-desktop.png");
        width:860px;
        height:427px;
    }
    @media(max-width:765px){
        background-image:url("https://lp-bria-assets.s3.amazonaws.com/home/vectorMobile.png");
        background-repeat:no-repeat;
        width:181px;
        height:511px;
    }
`

const tooglePositionItemDesktop = (index) => {
    switch (index) {
        case 0:
            return "-25px 0px 0px -60px ";
        case 1:
            return "-17px 0px 0px 360px ";
        case 2:
            return "-40px 0px 0px 765px ";
        case 3:
            return "290px 0px 0px 700px "
        case 4:
            return "380px 0px 0px 340px "
        case 5:
            return "320px 0px 0px 25px "
        default:
            break;
    }
}
const tooglePositionItemMobile = (index) => {
    switch (index) {
        case 0:
            return "-48px 0px 0px -80px";
        case 1:
            return "-48px 0px 0px 92px";
        case 2:
            return "201px 0px 0px -80px";
        case 3:
            return "201px 0px 0px 92px ";
        case 4:
            return "450px 0px 0px -80px ";
        case 5:
            return "447px 0px 0px 92px ";
        default:
            break;
    }
}

export const Item = styled.div`
    display:flex;
    position:absolute;
    width:156px;
    flex-direction:column;
    align-content:center;
    justify-content:center;

    @media(min-width:765px){
        margin:${props =>tooglePositionItemDesktop(props.identification)};
    }

    @media(max-width:765px){
        margin:${props =>tooglePositionItemMobile(props.identification)};
    }

    img{
        margin: auto;
        display:flex;
    }
    p{
        font-size:24px;
        font-weight:bold;
        font-family:"Montserrat";
        color:#ffff;
        margin:0;
        margin-top:10px;
        text-align:center;
        @media(max-width:350px){
            font-size:18px!important;
        }
    }
  
    :hover{
        animation: 1s ${keyframes`${pulse}`};
    }
`