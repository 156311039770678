import {ContainerHome,Background,WrapHeader} from "./styled.js"

const Header = () =>{
    return(
        <Background>
            <ContainerHome>
                <WrapHeader>
                    <img src={"https://lp-bria-assets.s3.amazonaws.com/LogoBlue.png"} alt="" />
                    <p>
                        Seguros do <br/>nosso futuro.
                    </p>
                </WrapHeader>
            </ContainerHome>
        </Background>
    )
}

export default Header