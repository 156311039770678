import styled from "styled-components"

export const WrapMenuMobile = styled.div`
    width:100%;
    height:69px;
    background-color:#2F054D;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
`
export const ButtomMenu = styled.button`
        background-color:transparent;
        border:none;
        margin-right:20px;
        display:flex;
`
export const Logo = styled.img`
    cursor: pointer;
    margin-left:20px;
`
export const MenuItens = styled.div`
    background-color:#2F054D;
    width:100%;
    height:100vh;
    z-index:3;
    position:fixed;
    top:0;
    padding:5rem 3rem;
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    align-items: baseline;
`
export const ButtonIntes = styled.button`
    border:none;
    background-color:transparent;
    color:  ${props => props.active === true ? "#F88675" : "white"};
    font-size:24px;
    font-weight:700;
    margin:10px auto;

` 
export const ButtonExit = styled.button`
    background-color:transparent;
    color:white;
    border:none;
    align-items: center;
    display: flex;
    justify-content: center;
`
export const Hr = styled.div`
    width:100%;
    height:1px;
    background-color:#ffffff73;
    margin-bottom:10px;
    margin-top:10px;
`
export const WrapSocialMedia = styled.div`
    display:flex;
    width: 200px;
    margin:0 auto;
    margin-top:3rem;
    bottom: 58px;
    justify-content: space-evenly;
    box-sizing: border-box;
    a{
        cursor: pointer;
        margin:0px 5px;
        width:30px;
    }
   
`
export const LogoMenu = styled.img`
   display:flex;

`
export const WrapHeader = styled.div`
    position:fixed;
    display:flex;
    top:0;
    left:0;
    box-sizing:border-box;
    align-content:center;
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    padding:20px;
    
`