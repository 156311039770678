

import {Wrap,Title,Subtitle,Background} from "./styled"
const Partnership = ( ) =>{
    return(
        <Background>
            <Wrap>
                <Title>
                Facilidade de verdade resolve por você e te dá tempo para <br/>pensar no que realmente importa: 
                </Title>
                <Subtitle>
                    o futuro do seu negócio.
                </Subtitle>
            </Wrap>
        </Background>
    )
}
export default Partnership