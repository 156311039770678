import styled, { keyframes } from 'styled-components';
import { pulse } from 'react-animations';

export const Background = styled.div`
  background:#2F054D;
  display:flex;
  flex-direction:column;
  align-content:center;
`;
export const Container = styled.div`
  padding-bottom:5rem;
  background-repeat:no-repeat;
  margin:auto;
  @media(max-width:765px){
    width:320px;
    height:4357px;
    background-image:url("https://lp-bria-assets.s3.amazonaws.com/solutions/bg-mobile-3.png");
    background-size:contain;
    margin-top:23rem;
  }
  @media(min-width:765px) and (max-width:1099px){
    width:765px;
    height:2458.18px;
    background-image:url("https://lp-bria-assets.s3.amazonaws.com/solutions/bg-sm.png");
  }
  @media(min-width:1099px){
    width:1099px;
    height:3474px;
    background-image:url("https://lp-bria-assets.s3.amazonaws.com/solutions/bd-desktop.png");
  }
`;
const tooglePositionWrapContent = (index,grid) => {
  switch (index) {
      case 0:
        if(grid==="X-Large"){
          return "28px 0px 0px 145px";
        }else
        if(grid==="Small"){
          return "-19px 101px";
        }else
        if(grid==="Mobile"){
          return "-325px 0";
        }
        break;
      case 1:
        if(grid==="X-Large"){
          return "724px 0px 0px 596px";
        }else
        if(grid==="Small"){
          return "476px 0 0 378px";
        }if(grid==="Mobile"){
          return "446px 0 0 -29px";
        }
        break;
      case 2:
        if(grid==="X-Large"){
          return "1204px 0px 0px 146px";
        }else
        if(grid==="Small"){
          return "832px 0 0 11px";
        }else
        if(grid==="Mobile"){
          return "1310px 0 0 19px";
        }
        break;
      case 3:
        if(grid==="X-Large"){
          return "1849px 0px 0px 742px";
        }else
        if(grid==="Small"){
          return "1275px 0 0 453px";
        }else
        if(grid==="Mobile"){
          return "1994px 0 0 -21px";
        }
        break;
      case 4:
        if(grid==="X-Large"){
          return "2530px 0px 0px 142px";
        }else
        if(grid==="Small"){
          return "1786px 0 0 42px";
        }
        else
        if(grid==="Mobile"){
          return "2810px 0 0 35px";
        }
        break;
      case 5:
        if(grid==="X-Large"){
          return "3145px 0px 0px 655px";
        }else
        if(grid==="Small"){
          return "2199px 0 0 430px";
        }else
        if(grid==="Mobile"){
          return "3625px 0 0 25px";
        }
        break;
      default:
          break;
  }
}

export const WrapContent = styled.div`
  display:flex;
  flex-direction:column;
  position:absolute;
  @media(max-width:765px){
    width:250px!important;
    margin:${props =>tooglePositionWrapContent(props.id,"Mobile")};
  }
  @media(min-width:765px) and (max-width:1099px){
    margin:${props =>tooglePositionWrapContent(props.id,"Small")};
    width:308px;
  }
  @media(min-width:1099px){
    margin:${props =>tooglePositionWrapContent(props.id,"X-Large")};
    width:359px;
  }
`
export const HeaderContent = styled.div`
  display:flex;
  flex-direction:row;
  img{
    width: fit-content;
    height:fit-content;

  }
  p{
    font-weight:bold;
    color:#fff;
    font-size:2.25rem;
    margin:0;
    margin-left:12px;
    font-family:"Montserrat";
    line-height:36px;
    margin-bottom:10px;
    @media(max-width:765px){
      font-size:1.8rem;
    }
  }
`
export const Body = styled.p`
  color:#F5F5F5;
  font-size:1.125rem;
  font-family:"Montserrat";
  font-weight:normal;
  @media(min-width:765px) and (max-width:1099px){
      font-size:16px;
    }
`

const tooglePositionImg = (index,grid) => {
  switch (index) {
      case 0:
        if(grid==="X-Large"){
          return "-46px 0 0 606px";
        }else 
        if(grid==="Small"){
          return "-126px 0 0 357px";
        }else 
        if(grid==="Mobile"){
          return"-113px 0";
        }
        break;
      case 1:
        if(grid==="X-Large"){
          return "484px 0 0 122px";
        }else 
        if(grid==="Small"){
          return "254px 0  0 21px";
        }else 
        if(grid==="Mobile"){
          return"812px 0";
        }
        break;
      case 2:
        if(grid==="X-Large"){
          return "1178px 0 0 433px";
        }else 
        if(grid==="Small"){
          return "782px 0 0 197px";
        }else 
        if(grid==="Mobile"){
          return"1612px 0";
        }
        break;
      case 3:
        if(grid==="X-Large"){
          return "1830px 0px 0px 43px";
        }else 
        if(grid==="Small"){
          return "1238px 0 0 -120px";
        }else 
        if(grid==="Mobile"){
          return "2399px 0";
        }
        break;
      case 4:
        if(grid==="X-Large"){
          return "2329px 0px 0px 609px";
        }else 
        if(grid==="Small"){
          return "1556px 0 0 370px";
        }
        else 
        if(grid==="Mobile"){
          return "3115px  0";
        }
        break;
      case 5:
        if(grid==="X-Large"){
          return "3092px 0px 0px 137px";
        }else 
        if(grid==="Small"){
          return "2126px 0 0 34px";
        }else
        if(grid==="Mobile"){
          return "3930px 0";
        }
        break;
      default:
          break;
  }
}
export const Img = styled.img`
  position:absolute;

  @media(max-width:765px){
    transform:${props =>props.id === 2 || props.id === 3 ? "scale(0.47, 0.47)" : "scale(0.7, 0.7)"};
    margin:${props =>tooglePositionImg(props.id,"Mobile")};
  }
  
  @media(min-width:1099px){
    margin:${props =>tooglePositionImg(props.id,"X-Large")};
    :hover{
      animation: 2s ${keyframes`${pulse}`};
    }
  }
  @media(min-width:765px) and (max-width:1099px){
    transform: scale(0.7, 0.7);
    -ms-transform: scale(0.7, 0.7);
    -webkit-transform: scale(0.7, 0.7);
    margin:${props =>tooglePositionImg(props.id,"Small")};
    :hover{
        animation: 1s ${keyframes`${pulse}`};
    }
  }
`
export const Section = styled.section`
   @media(max-width:765px){
      display:flex;
      flex-direction:column;
      align-items:center;
  }
` 