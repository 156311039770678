import styled from 'styled-components';

export const Background = styled.div`
    background-color:white;
`;

export const Container = styled.div`
    max-width:1140px;
    margin:auto;
    display:flex;
    align-items:center;
    margin-bottom:2rem;

    @media(min-width:765px){
        flex-direction:row;
    }
    @media(max-width:765px){
        flex-direction:column-reverse;
    }
`;

export const ColLeft = styled.div`
    width:40%;
    align-items:center;
    display:flex;
    flex-direction:column;

    p{
        color:#2F054D;
        font-size:36px;
        font-weight:bold;
        font-family:"Montserrat";

        .uma::before{
            content:" "
        };
        .o::before{
            content:" "
        };
        .ao::before{
            content:" "
        };
        .uma::after{
            content:' Inteligência facilitadora ';
            color:#F88675;
            text-decoration:none;
        };
        .o::after{
            content:' mercado de seguros';
            text-decoration:underline;
        }; 
        .ao::after{
            content:'segurado';
            text-decoration:underline;
        };       
    };

    span{
        font-size:24px;
        font-weight:400;
        font-family:"Montserrat";
        color:#5F5F5F;
    }
    @media(max-width:765px){
        width:100%;
        padding:0rem 2rem;
        box-sizing:border-box;
        font-size:28px;
    }

`;

export const ColRigth = styled.div`
    @media(min-width:765px){
        width:60%;
        img{
            margin-top:-150px;
        }
    }
    @media(max-width:765px){
        width:100%;
        img{
            width:100%;
            margin-top:-170px;
        }
    }
`;