import {
  Wrap,
  Container,
  WrapButtons,
  WrapSocialMedia,
  Button,
  Hr,
} from "./styled";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const Menu = () => {
  const [pathLocation, setPathLocation] = useState();
  const [refresh, setRefresh] = useState(false);

  let history = useHistory();

  const toggleActive = (pathButton) => {
    if (pathButton === pathLocation) {
      return true;
    } else {
      return false;
    }
  };
  const handdleRedirect = (path) => {
    setRefresh(!refresh);
    history.push(path);
  };

  useEffect(() => {
    if (window) {
      setPathLocation(window.location.pathname);
    }
  }, [refresh]);

  return (
    <Wrap>
      <Container>
        <img
          src="https://lp-bria-assets.s3.amazonaws.com/briaLogo.png"
          alt=""
        />
        <WrapButtons>
          <Button
            onClick={() => handdleRedirect("/")}
            active={toggleActive("/")}
          >
            Home
          </Button>
          <Hr />
          <Button
            onClick={() => handdleRedirect("/solucoes")}
            active={toggleActive("/solucoes")}
          >
            Soluções
          </Button>
          <Hr />
          <Link to="/#contact">
            <Button active={toggleActive("solution")}>Contato</Button>
          </Link>
        </WrapButtons>
        <WrapSocialMedia>
          <a
            href="https://www.facebook.com/profile.php?id=100070141573693"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={"https://lp-bria-assets.s3.amazonaws.com/facebook.png"}
              alt=""
            />
          </a>
          <a
            href="https://instagram.com/briasaude"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={"https://lp-bria-assets.s3.amazonaws.com/instagram.png"}
              alt=""
            />
          </a>
          <a
            href="https://br.linkedin.com/company/briasaude"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={"https://lp-bria-assets.s3.amazonaws.com/linkedin.png"}
              alt=""
            />
          </a>
        </WrapSocialMedia>
      </Container>
    </Wrap>
  );
};
export default Menu;
