import {Background,P,H1,Button, Wrap, Item} from "./styled"

const phasesJorney = [
    { 
        img: "https://lp-bria-assets.s3.amazonaws.com/home/hiring.png" ,
        text:"Contratação"
    },
    { 
        img: "https://lp-bria-assets.s3.amazonaws.com/home/smart.png" ,
        text:"DPS   Inteligente"
    },
    { 
        img: "https://lp-bria-assets.s3.amazonaws.com/home/Cheers.png" ,
        text:"Inteligência   em saúde"
    },
    { 
        img: "https://lp-bria-assets.s3.amazonaws.com/home/analytcs.png" ,
        text:"Dashboard  analítico"
    },
    { 
        img: "https://lp-bria-assets.s3.amazonaws.com/home/gamification.png" ,
        text:"Bem-estar e gamificação"
    },
    { 
        img: "https://lp-bria-assets.s3.amazonaws.com/home/humanization.png" ,
        text:"Assitência humnanizada gamificação"
    },
]

const DigitalJorney = () =>{
    return(
        <Background>
            <H1>Jornada digital</H1>
            <P>Base tecnológica que prepara a sua empresa para o futuro.</P>
            <Wrap>
                {
                    phasesJorney.map(function(item, index){
                            return (
                                <Item identification={index} key={index}>
                                    <img  src={item.img} alt=""/>
                                    <p >{item.text}</p>
                                </Item>
                            );
                        })
                    }
            </Wrap>
            <Button>Como funciona?</Button>
        </Background>
    )
}

export default DigitalJorney