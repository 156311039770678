import GetMobileScreen from "../../../utils/getMobileScreen"
import {Wrap,Title,Subtitle,Background,ImgB} from "./styled"
const Partnership = ( ) =>{
    return(
        <>
        {
            GetMobileScreen() ? 
                <div style={{background:"#F88675"}}>
                    <Wrap>
                        <Title>
                            A gente acredita em parceria.
                            Sem essa de ir na frente. 
                        </Title>
                        <Subtitle>
                            A gente corre lado a lado.
                        </Subtitle>
                    </Wrap>
                    <ImgB src={"https://lp-bria-assets.s3.amazonaws.com/home/banner-last-mobile.png"} alt=""/>
                </div>
            :
                <Background>
                    <Wrap>
                        <Title>
                            A gente acredita em parceria.
                            <br/>
                            Sem essa de ir na frente. 
                        </Title>
                        <Subtitle>
                            A gente corre lado a lado.
                        </Subtitle>
                    </Wrap>
                </Background>
        }
        </>
    )
}
export default Partnership
