import {Wrap,Item, H1} from "./styled"

const benefitsInfo = [
    {
        imgSrc:"https://lp-bria-assets.s3.amazonaws.com/home/benefits-1.svg",
        listen:"Inteligência de dados para tomada de decisão;"
    },
    {
        imgSrc:"https://lp-bria-assets.s3.amazonaws.com/home/benefits-2.svg",
        listen:"Estudos estatísticos de saúde à médio e longo prazo;"
    },
    {
        imgSrc:"https://lp-bria-assets.s3.amazonaws.com/home/benefetis-3.svg",
        listen:"Premissas atuariais, socioeconômicas e comportamentais; "
    },
    {
        imgSrc:"https://lp-bria-assets.s3.amazonaws.com/home/benefetis-4.svg",
        listen:"Retroalimentação de dados; "
    },
    {
        imgSrc:"https://lp-bria-assets.s3.amazonaws.com/home/benefits-5.png",
        listen:"Dashboard analítico para gestão de dados;"
    },
    {
        imgSrc:"https://lp-bria-assets.s3.amazonaws.com/home/benefefits-6.png",
        listen:"Atendimento via telesubscrição."
    },
]

const Benefits = () =>{
    return(
        <>
            <H1>Benefícios</H1>
            <Wrap>
                {
                    benefitsInfo.map(function(item, indice){
                        return (
                            <Item key={indice}>
                                <img  src={item.imgSrc} alt=""/>
                                <p >{item.listen}</p>
                            </Item>
                        );
                    })
                }
            </Wrap>
        </>
    )
}

export default Benefits