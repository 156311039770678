import styled, { keyframes } from 'styled-components';
import { pulse } from 'react-animations';

export const Wrap = styled.div`
  max-width:800px;
  display:flex;
  margin:auto;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-bottom:2rem;
`;

export const Item = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    margin:15px;
    p{
        font-family:"Montserrat";
        font-weight:400;
        font-size:20px;
        color:#2F054D;
        text-align: center;
        @media(max-width:765px){
            font-size:16px;
        }
    }
    @media(min-width:765px){
        width:200px;
    }
    @media(max-width:765px){
        width:40%;
    }

    :hover{
        animation: 1s ${keyframes`${pulse}`};
    }
`
export const H1 = styled.h1`
    font-family:"Montserrat";
    font-weight:700;
    font-size:50px;
    color:#2F054D;    
    text-align: center;
    @media(max-width:765){
        font-family:48px;
    }
`