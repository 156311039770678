import styled from 'styled-components';

export const Background = styled.div`
    
    background-repeat:no-repeat!important;
    background-size:cover!important;
    background-position-y:initial!important;
    display:flex;
    align-items:center;
    justify-content:center;
    height:522px;
    background:url("https://lp-bria-assets.s3.amazonaws.com/solutions/banner_final.png");
   
`;
export const Wrap = styled.div`
    display:flex;
    flex-direction:column;
    @media(min-width:765px){
        width:693px;
    }
    @media(max-width:765px){
        padding:30px;
    }
`;
export const Title = styled.p`
    color:#fff;
    font-size:36px;
    font-weight:bold;
    font-family:"Montserrat";
    margin:0;
`;
export const Subtitle = styled.p`
    color:#F88675;
    font-size:36px;
    font-weight:bold;
    font-family:"Montserrat";
    margin:0;
    height:fit-content;
`;