import { Address, Background, Container, WrapSocialMedia } from "./styled";

const Footer = () => {
  return (
    <Background>
      <Container>
        <img
          src="https://lp-bria-assets.s3.amazonaws.com/footer/LogoFooter.png"
          alt=""
        />

        <WrapSocialMedia>
          <a
            href="https://www.facebook.com/profile.php?id=100070141573693"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://lp-bria-assets.s3.amazonaws.com/footer/facebook.png"
              alt="facebook"
            />
          </a>
          <a
            href="https://br.linkedin.com/company/briasaude"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://lp-bria-assets.s3.amazonaws.com/footer/linkedin.png"
              alt="linkedin"
            />
          </a>
          <a
            href="https://instagram.com/briasaude"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://lp-bria-assets.s3.amazonaws.com/footer/instagram+1.png"
              alt="instagram"
            />
          </a>
        </WrapSocialMedia>
      </Container>
      <Address>
        Bria Saúde - Inteligência em Seguros <br />
        Av. Prof. Othon Gama D'Eça, 677 - Sala 602 <br />
        CEP 88015-240, Florianópolis - SC <br />
        Telefone: (48) 3067-4280 Email: contato@briasaude.com
      </Address>
    </Background>
  );
};
export default Footer;
