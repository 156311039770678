import {Header,Background} from "./styled"

const HeaderMain = () => {
    return(
        <Background>
            <Header>
                <strong>
                    Ampliamos as possibilidades de futuro para
                    <q className="a">
                    a
                    </q>
                </strong>
                <br/>
                Na mesma medida que <strong>intensificamos</strong> as relações com a saúde no hoje, no ato.
            </Header>
        </Background>
    )
}

export default HeaderMain