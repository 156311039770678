import {useState,useEffect} from "react"

 const GetMobileScreen = () =>{
    const [sizeScreen, setSizeScreen] = useState()
    const [mobileScreen , setMobileScreen] = useState(Boolean)
    
    useEffect(()=>{
        if(window){
            function handleResize() {
                setSizeScreen(window.innerWidth);
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    },[])
    useEffect(()=>{
        sizeScreen < 765 ? setMobileScreen(true) : setMobileScreen(false)        
    },[sizeScreen])
    return mobileScreen
}
export default GetMobileScreen