import { Background,Container,ColLeft,ColRigth } from "./styled"
import { useSpring, animated } from 'react-spring'
import GetMobileScreen from "../../../utils/getMobileScreen"


const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`



const FacilitingInteligence = () =>{
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))
    return(
        <Background>
            <Container>
                <ColLeft>
                    <p>
                        Somos<q className={"uma"}>uma</q> 
                        para <q className="o">o</q>.
                        Enquanto oferecemos agilidade <q className="ao">ao </q>.
                    </p>
                    <span>
                        Aliado à tecnologia e análise de dados estatísticos de saúde, nosso algoritmo auxilia em todo processo de subscrição da seguradora. 
                    </span>
                </ColLeft>
                { GetMobileScreen() ?
                    <ColRigth>
                        <img src="https://lp-bria-assets.s3.amazonaws.com/home/phone_home.png" alt="" />
                    </ColRigth> 
                    :
                    <animated.div
                    onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                    onMouseLeave={() => set({ xys: [0, 0, 1] })}
                    style={{ transform: props.xys.interpolate(trans) }}
                    >
                        <ColRigth>
                            <img src="https://lp-bria-assets.s3.amazonaws.com/home/phone_home.png" alt="" />
                        </ColRigth>
                    </animated.div>    
                }
            </Container>
        </Background>
    )
}

export default FacilitingInteligence