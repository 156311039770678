import Header from "../component/home/header"
import FacilitatingInteligence from "../component/home/facilitatingInteligence"
import Benefits from "../component/home/benefits"
import DigitalJorney from "../component/home/digitalJorney"
import Contact from "../component/home/contact"
import Partnership from "../component/home/partnership"
const Home = () =>{
    return(
        <>
            <Header/>
            <FacilitatingInteligence />
            <Benefits/>
            <DigitalJorney/>
            <Contact/>
            <Partnership/>
        </>
    )
}

export default Home